import { AssetItemType, SmartObjectDto } from 'Api/Contracts/Dtos';
import { CreateSmartObjectInputDto } from 'Api/Contracts/Dtos/SmartObject';
import { IServerPaginationResult } from 'Api/Dto/QueryResult';
import HttpClient from 'Api/HttpClient';
import { Routes } from 'Api/Routes';
import { inject, injectable } from 'inversify';

export type MeasuringDevice = {
    name: string;
    serialNumber?: string;
    referenceNumber?: string;
}

export type ProbeDevice = MeasuringDevice & {
    working: string;
    surface: UnitValue;
    timeMeasuring: UnitValue;
    detection: Detection<ProbeFeature>;
}

export type BenchDevice = MeasuringDevice & {
    detection: Detection<BenchFeature>;
}

export type IrradiationDevice = MeasuringDevice & {
    detection: {
        gamma?: {
            limitDetection: UnitValue;
        };
        neutron?: {
            limitDetection: UnitValue;
        };
    };
    surfaceDensity?: Array<UnitValue>;
}

export function isProbeDevice(device: ProbeDevice | BenchDevice): device is ProbeDevice {
    return 'surface' in device;
}

export type Detection<T> = {
    alpha?: T;
    beta?: T;
}

export type ProbeFeature = {
    efficiency: Efficiency;
    conversion: Conversion;
    detectionThreshold: ThresholdDetection;
    limitDetection: LimitDetection;
}

export type BenchFeature = {
    efficiency: Efficiency;
    bdfMax: UnitValue;
    limitDetection300: LimitDetectionTBdf;
    limitDetection600: LimitDetectionTBdf;
}

export type Efficiency = {
    ei: UnitValue;
    es: number;
    ri: UnitValue;
}

export type UnitValue = {
    value: number;
    symbol: Symbol;
}

export type WasteZoning = 'ZDN' | 'ZDC'

export type ZoningRadioValue = {
    color: string;
    rca: number;
}
export type ZoningRadio = 'public' | 'supervised' | 'green' | 'yellow' | 'orange' | 'forbidden'
export const RadiologicalZoning: Record<ZoningRadio, ZoningRadioValue> = {
    public: {
        color: 'white',
        rca: 0
    },
    supervised: {
        color: 'cyan',
        rca: 0.000033602150537634 //80µSv / month
    },
    green: {
        color: 'green',
        rca: 0.0075 //7.5µSv / hour
    },
    yellow: {
        color: 'yellow',
        rca: 0.025 //25µSv / hour
    },
    orange: {
        color: 'orange',
        rca: 2 //2mSv / hour
    },
    forbidden: {
        color: 'red',
        rca: 100 //100mSv / hour
    },
}
export type Symbol =
    | '%'
    | 'c/s'
    | 'Bq'
    | 'Bq/cm²'
    | 'cm²'
    | 'm²'
    | 's'
    | 'mg/cm²'
    | 'g/cm²'
    | 'mSv/h'

export type ContaminationControlMode = 'Probe' | 'Smear' | 'Paper'
export type IrradiationControlMode = 'Contact' | 'Ambient'

export type Conversion = {
    bq: number;
    direct: UnitValue;
    smear: UnitValue;
}

export type ThresholdDetection = {
    sdNet: UnitValue;
    usualBdf: UnitValue;
    sdbdf: UnitValue;
}

export type LimitDetection = {
    direct: UnitValue;
    smear: UnitValue;
}

export type LimitDetectionTBdf = {
    sample: UnitValue;
    ldCs: UnitValue;
    ldBq: UnitValue;
    ldBqCm: UnitValue;
}

export type SurfaceDensityRadiation = {
    reading: UnitValue;
    surfaceDensity: UnitValue;
    measuringDevice: IrradiationDevice;
}

export type RadiationValue = {
    gamma?: SurfaceDensityRadiation;
    neutron?: SurfaceDensityRadiation;
}

//////////////////////////////////////////////////////////////////////////////////////////
export type Employee = {
    fullName: string;
}

export type UsedDevice = {
    contamination: {
        probes: Array<ProbeDevice>;
        benches: Array<BenchDevice>;
    };
    irradiations: Array<IrradiationDevice>;
}

export type Cartography = {
    date: Date;
    room: string;
    wasteZoning: WasteZoning;
    radiologicalZoning: ZoningRadio;
    controllers: Array<Employee>;
    auditors: Array<Employee>;
    usedDevice: UsedDevice;
    reasonForMapping: string;
    observations: string;
    contaminations: Array<Contamination>;
    irradiations: Array<Irradiation>;
}

export type Contamination = {
    id: number;
    modeControl: ContaminationControlMode;
    alpha?: number;
    beta?: number;
    unit: string;
    rubbedSurface: UnitValue | null;
    contaminatedSurface: UnitValue | null;
    observations?: string;
    includedInCalculation: boolean;
    measuringDevice: ProbeDevice | BenchDevice;
}

export type Irradiation = {
    id: number;
    contact: RadiationValue;
    ambient: RadiationValue & {
        includedInCalculation: boolean;
    };
    modeControl: IrradiationControlMode;
    observations?: string;
}

export function isContamination(item: Contamination | Irradiation): item is Contamination {
    switch (item.modeControl) {
        case 'Probe':
        case 'Smear':
        case 'Paper':
            return true;
        default:
            return false;
    }
}

export function isIrradiation(item: Contamination | Irradiation): item is Irradiation {
    switch (item.modeControl) {
        case 'Ambient':
        case 'Contact':
            return true;
        default:
            return false;
    }
}

export interface IContaminationInputDto
    extends CreateSmartObjectInputDto<Contamination> {
}

export interface IIrradiationInputDto
    extends CreateSmartObjectInputDto<Irradiation> {
}

/**
 *  Valeur du c/s en Bq/cm² pour un contrôle direct
 * @param hit c/s
 * @param ei Rendement minimal admissible de l'instrument ɛi en α (2π)
 * @param es Rendement de source ɛs
 * @param w Surface de la fenêtre d'entrée de l'instrument de mesure en cm²
 * @returns Bq/cm²
 */
export function directToBecquerelPerCm2(hit: number, ei: number, es: number, w: number): number {
    return hit / (ei * es * w);
}

/**
 *  Valeur du c/s en Bq/cm² pour un contrôle par frottis (mesure indirecte)
 * @param hit c/s
 * @param ei Rendement minimal admissible de l'instrument ɛi en α (2π)
 * @param es Rendement de source ɛs
 * @param s Surface frottée en cm²
 * @returns Bq/cm²
 */
export function smearToBecquerelPerCm2(hit: number, ei: number, es: number, s: number): number {
    return hit / (ei * es * s * 0.10); //0.10 = Facteur de prélèvement F
}

export interface IContaminationService {
    searchCartographyAsync(
        projectId: number,
        currentRoom?: string): Promise<IServerPaginationResult<SmartObjectDto<Cartography>>>;

    getCartography(
        projectId: number,
        currentRoom: string): Promise<SmartObjectDto<Cartography>>;
}

@injectable()
export class ContaminationService implements IContaminationService {
    public constructor(@inject(HttpClient) httpClient: HttpClient) {
        this._httpClient = httpClient;
    }

    public async searchCartographyAsync(
        projectId: number,
        currentRoom?: string
    ): Promise<IServerPaginationResult<SmartObjectDto<Cartography>>> {
        let url = Routes.Api.SmartObjects.Base;

        const response = await this._httpClient.getAsync(url, null, {
            type: 'Cartography',
            projectId: projectId,
            // search: search,
            // sortOrder: sortOrder,
            // skip: (page - 1) * pageSize,
            // top: pageSize
        });

        return await response.json() as IServerPaginationResult<SmartObjectDto<Cartography>>;
    }

    public async getCartography(
        projectId: number,
        currentRoom: string,
    ): Promise<SmartObjectDto<Cartography>> {
        let url = Routes.Api.SmartObjects.Base;

        const response = await this._httpClient.getAsync(url, null, {
            type: 'Cartography',
            projectId: projectId
            // search: search,
            // sortOrder: sortOrder,
            // skip: (page - 1) * pageSize,
            // top: pageSize
        });

        const found = await response.json() as IServerPaginationResult<SmartObjectDto<Cartography>>;

        const results: Array<SmartObjectDto<Cartography>> = currentRoom
            ? found.result.filter(c => c.dataSource.room == currentRoom)
            : found.result;

        results.sort((a, b) =>
            new Date(b.dataSource.date).getTime() - new Date(a.dataSource.date).getTime());

        return results[0];
    }

    protected readonly _httpClient: HttpClient;
}

@injectable()
export class FakeContaminationService implements IContaminationService {
    public async searchCartographyAsync(
        projectId: number,
        currentRoom?: string
    ): Promise<IServerPaginationResult<SmartObjectDto<Cartography>>> {
        const fakeCartos = ([
            {
                assetItemId: 1000,
                name: 'CFA8BDAD-BFB4-4633-82B1-EF385B38C017',
                type: AssetItemType.SmartObject,
                dataSource: {
                    auditors: [],
                    contaminations: [
                        {
                            id: 1,
                            modeControl: 'Smear',
                            includedInCalculation: true,
                            unit: 'c/s',
                            alpha: 0.024,
                            beta: 0.04275,
                            rubbedSurface: {
                                value: 300,
                                symbol: 'cm²'
                            },
                            contaminatedSurface: {
                                value: 5,
                                symbol: 'cm²'
                            },
                            observations: 'Sol',
                            measuringDevice: this.SAB100,
                        },
                        {
                            id: 2,
                            modeControl: 'Smear',
                            includedInCalculation: true,
                            unit: 'c/s',
                            alpha: 0.024,
                            beta: 0.04275,
                            rubbedSurface: {
                                value: 300,
                                symbol: 'cm²'
                            },
                            contaminatedSurface: {
                                value: 10,
                                symbol: 'cm²'
                            },
                            observations: 'Bague pneumatique, navette PuO2',
                            measuringDevice: this.SAB100,
                        },
                        {
                            id: 3,
                            modeControl: 'Smear',
                            includedInCalculation: false,
                            unit: 'c/s',
                            alpha: 0.024,
                            beta: 0.04275,
                            rubbedSurface: null,
                            contaminatedSurface: null,
                            observations: 'Sol',
                            measuringDevice: this.SAB100,
                        },
                        {
                            id: 4,
                            modeControl: 'Smear',
                            includedInCalculation: false,
                            unit: 'c/s',
                            alpha: 0.024,
                            beta: 0.04275,
                            rubbedSurface: null,
                            contaminatedSurface: null,
                            observations: 'Sol',
                            measuringDevice: this.SAB100,
                        },
                        {
                            id: 5,
                            modeControl: 'Paper',
                            includedInCalculation: true,
                            unit: 'c/s',
                            alpha: 0.024,
                            beta: 0.04275,
                            rubbedSurface: {
                                value: 300,
                                symbol: 'cm²'
                            },
                            contaminatedSurface: {
                                value: 10,
                                symbol: 'cm²'
                            },
                            observations: 'Sol',
                            measuringDevice: this.Bench60,
                        },
                        {
                            id: 6,
                            modeControl: 'Probe',
                            includedInCalculation: true,
                            unit: 'c/s',
                            alpha: 0.5,
                            beta: 5,
                            rubbedSurface: null,
                            contaminatedSurface: {
                                value: 10,
                                symbol: 'cm²'
                            },
                            observations: 'Paillasse',
                            measuringDevice: this.SAB100,
                        }
                    ],
                    irradiations: [
                        {
                            id: 7,
                            observations: 'F956',
                            modeControl: 'Contact',
                            ambient: {
                                includedInCalculation: true,
                                gamma: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.01
                                    },
                                    surfaceDensity: {
                                        symbol: 'mg/cm²',
                                        value: 7
                                    },
                                    measuringDevice: this.Babyline
                                },
                                neutron: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.02
                                    },
                                    surfaceDensity: {
                                        symbol: 'g/cm²',
                                        value: 1
                                    },
                                    measuringDevice: this.Babyline
                                },
                            },
                            contact: {
                                gamma: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.03
                                    },
                                    surfaceDensity: {
                                        symbol: 'mg/cm²',
                                        value: 7
                                    },
                                    measuringDevice: this.Babyline
                                },
                                neutron: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.04
                                    },
                                    surfaceDensity: {
                                        symbol: 'g/cm²',
                                        value: 1
                                    },
                                    measuringDevice: this.Babyline
                                },
                            }
                        },
                        {
                            id: 8,
                            observations: 'F956',
                            modeControl: 'Contact',
                            ambient: {
                                includedInCalculation: true,
                                gamma: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.01
                                    },
                                    surfaceDensity: {
                                        symbol: 'mg/cm²',
                                        value: 7
                                    },
                                    measuringDevice: this.Babyline
                                },
                                neutron: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.02
                                    },
                                    surfaceDensity: {
                                        symbol: 'g/cm²',
                                        value: 1
                                    },
                                    measuringDevice: this.Babyline
                                },
                            },
                            contact: {
                                gamma: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.03
                                    },
                                    surfaceDensity: {
                                        symbol: 'mg/cm²',
                                        value: 7
                                    },
                                    measuringDevice: this.Babyline
                                },
                                neutron: {
                                    reading: {
                                        symbol: 'mSv/h',
                                        value: 0.04
                                    },
                                    surfaceDensity: {
                                        symbol: 'g/cm²',
                                        value: 1
                                    },
                                    measuringDevice: this.Babyline
                                },
                            }
                        }
                    ],
                    controllers: [],
                    observations: 'Frottis papier au niveau des sols, frottis chiffonnette sur organe\nContrôle sonde αβ sur paillasse\nMesures DeD sur filtres toit BàG + filtres RTP (si > 0,5 mSv.h-1 Télétector, prévénir RE ou Adj.)\nMesures DeD ambiant au poste de travail : si DeD > 7,5 µSv.h-1  prévénir RE ou Adj.\nConta int BAG 7151-50',
                    date: new Date('2023-02-09T14:30:40.0437114+02:00'),
                    reasonForMapping: 'Contrôle cellule bimestriel',
                    room: '707.2',
                    wasteZoning: 'ZDN',
                    radiologicalZoning: 'public',
                    usedDevice: {
                        contamination: {
                            probes: [this.SAB100],
                            benches: []
                        },
                        irradiations: []
                    }
                }
            },
            {
                assetItemId: 1001,
                name: 'CA02D876-9194-4DBE-BB86-3717A3FAA004',
                type: AssetItemType.SmartObject,
                dataSource: {
                    auditors: [],
                    contaminations: [],
                    controllers: [],
                    irradiations: [],
                    observations: 'Frottis papier au niveau des sols, frottis chiffonnette sur organe\nContrôle sonde αβ sur paillasse\nMesures DeD sur filtres toit BàG + filtres RTP (si > 0,5 mSv.h-1 Télétector, prévénir RE ou Adj.)\nMesures DeD ambiant au poste de travail : si DeD > 7,5 µSv.h-1  prévénir RE ou Adj.\nConta int BAG 7151-50 : 2c/s alpha exploitant prévenu',
                    date: new Date('2023-04-06T06:00:40.0437114+02:00'),
                    reasonForMapping: 'Contrôle cellule bimestriel',
                    room: '707.2',
                    wasteZoning: 'ZDN',
                    radiologicalZoning: 'public',
                    usedDevice: {
                        contamination: {
                            probes: [],
                            benches: []
                        },
                        irradiations: []
                    }
                }
            },
            {
                assetItemId: 1002,
                name: 'DF61C0AD-EA58-4C89-B984-23744BEA713E',
                type: AssetItemType.SmartObject,
                dataSource: {
                    auditors: [],
                    contaminations: [],
                    controllers: [],
                    irradiations: [],
                    observations: 'Frottis papier au niveau des sols, frottis chiffonnette sur organe\nContrôle sonde αβ sur paillasse\nMesures DeD sur filtres toit BàG + filtres RTP (si > 0,5 mSv.h-1 Télétector, prévénir RE ou Adj.)\nMesures DeD ambiant au poste de travail : si DeD > 7,5 µSv.h-1  prévénir RE ou Adj.\nConta int BAG 7151-50',
                    date: new Date('2023-06-06T13:00:40.0437114+02:00'),
                    reasonForMapping: 'Contrôle cellule bimestriel',
                    room: '707.2',
                    wasteZoning: 'ZDN',
                    radiologicalZoning: 'public',
                    usedDevice: {
                        contamination: {
                            probes: [],
                            benches: []
                        },
                        irradiations: []
                    }
                }
            },
            {
                assetItemId: 1003,
                name: '4EF00DCA-2E29-40DD-8B03-55953F9EF85A',
                type: AssetItemType.SmartObject,
                dataSource: {
                    auditors: [],
                    contaminations: [],
                    controllers: [],
                    irradiations: [],
                    observations: 'Frottis papier au niveau des sols, frottis chiffonnette sur organe\nContrôle sonde αβ sur paillasse\nMesures DeD sur filtres toit BàG + filtres RTP (si > 0,5 mSv.h-1 Télétector, prévénir RE ou Adj.)\nMesures DeD ambiant au poste de travail : si DeD > 7,5 µSv.h-1  prévénir RE ou Adj.\nConta int BAG 7151-50',
                    date: new Date('2023-08-02T08:00:40.0437114+02:00'),
                    reasonForMapping: 'Contrôle cellule bimestriel',
                    room: '707.2',
                    wasteZoning: 'ZDN',
                    radiologicalZoning: 'public',
                    usedDevice: {
                        contamination: {
                            probes: [],
                            benches: []
                        },
                        irradiations: []
                    }
                }
            },
            {
                assetItemId: 1004,
                name: '8EF00DCA-2E29-40DD-8B03-55953F9EF90C',
                type: AssetItemType.SmartObject,
                dataSource: {
                    auditors: [],
                    contaminations: [],
                    controllers: [],
                    irradiations: [],
                    observations: 'Frottis papier au niveau des sols, frottis chiffonnette sur organe\nContrôle sonde αβ sur paillasse',
                    date: new Date('2023-02-09T15:20:40.0437114+02:00'),
                    reasonForMapping: 'Contrôle cellule bimestriel',
                    room: '720.2',
                    wasteZoning: 'ZDN',
                    radiologicalZoning: 'public',
                    usedDevice: {
                        contamination: {
                            probes: [],
                            benches: []
                        },
                        irradiations: []
                    }
                }
            }
        ] as Array<SmartObjectDto<Cartography>>);

        return {
            result: fakeCartos.filter(c => currentRoom ? c.dataSource.room == currentRoom : true),
            nextLink: ''
        } as IServerPaginationResult<SmartObjectDto<Cartography>>;
    }

    public async getCartography(projectId: number, currentRoom: string): Promise<SmartObjectDto<Cartography>> {
        const found = await this.searchCartographyAsync(projectId);

        return found.result
            .filter(c => c.dataSource.room == currentRoom)
            .sort((a, b) =>
                new Date(b.dataSource.date).getTime() - new Date(a.dataSource.date).getTime())[0];
    }

    private readonly SAB100: ProbeDevice = {
        referenceNumber: '20000',
        ...ContaminationDevices.find(c => c.name == 'SAB100')
    };

    private readonly Babyline: IrradiationDevice = {
        referenceNumber: '40000',
        ...IrradiationDevices.find(c => c.name == 'Babyline')
    };

    private readonly Bench60: BenchDevice = {
        referenceNumber: '60000',
        ...ContaminationBenches.find(c => c.name == 'Banc de comptage ∅60mm')
    };
}

//////////////////////////////////////////////////////////////////////////////////////////
export const ContaminationDevices: ReadonlyArray<ProbeDevice> = [
    {
        "name": "SAB100",
        "working": "Pretemps",
        "surface": {
            "value": 100,
            "symbol": "cm²"
        },
        "timeMeasuring": {
            "value": 5,
            "symbol": "s"
        },
        "detection": {
            "alpha": {
                "efficiency": {
                    "ei": {
                        "value": 32,
                        "symbol": "%"
                    },
                    "es": 0.25,
                    "ri": {
                        "value": 8,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 13,
                    "direct": {
                        "value": 0.1,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.4,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 0.7,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 0.3,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 1.0,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 0.4,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 1.2,
                        "symbol": "Bq/cm²"
                    }
                }
            },
            "beta": {
                "efficiency": {
                    "ei": {
                        "value": 28.5,
                        "symbol": "%"
                    },
                    "es": 0.5,
                    "ri": {
                        "value": 14,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 7,
                    "direct": {
                        "value": 0.1,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.2,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 2.8,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 5.0,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 7.8,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 0.5,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 1.7,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    },
    {
        "name": "SA70-2",
        "working": "Ictomètre/CAB",
        "surface": {
            "value": 30,
            "symbol": "cm²"
        },
        "timeMeasuring": {
            "value": 5,
            "symbol": "s"
        },
        "detection": {
            "alpha": {
                "efficiency": {
                    "ei": {
                        "value": 28,
                        "symbol": "%"
                    },
                    "es": 0.25,
                    "ri": {
                        "value": 7,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 14,
                    "direct": {
                        "value": 0.5,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.5,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 0.5,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 0.3,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 0.8,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 0.5,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.5,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    },
    {
        "name": "SA32",
        "working": "Pretemps/MIP",
        "surface": {
            "value": 30,
            "symbol": "cm²"
        },
        "timeMeasuring": {
            "value": 10,
            "symbol": "s"
        },
        "detection": {
            "alpha": {
                "efficiency": {
                    "ei": {
                        "value": 28,
                        "symbol": "%"
                    },
                    "es": 0.25,
                    "ri": {
                        "value": 7,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 14,
                    "direct": {
                        "value": 0.5,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.5,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 0.5,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 0.3,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 0.8,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 0.8,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.8,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    },
    {
        "name": "SAB100 grille fine",
        "working": "Pretemps",
        "surface": {
            "value": 100,
            "symbol": "cm²"
        },
        "timeMeasuring": {
            "value": 5,
            "symbol": "s"
        },
        "detection": {
            "alpha": {
                "efficiency": {
                    "ei": {
                        "value": 18,
                        "symbol": "%"
                    },
                    "es": 0.25,
                    "ri": {
                        "value": 5,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 22,
                    "direct": {
                        "value": 0.2,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.7,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 0.7,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 0.3,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 1.0,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 0.6,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 2.1,
                        "symbol": "Bq/cm²"
                    }
                }
            },
            "beta": {
                "efficiency": {
                    "ei": {
                        "value": 22,
                        "symbol": "%"
                    },
                    "es": 0.5,
                    "ri": {
                        "value": 11,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 9,
                    "direct": {
                        "value": 0.1,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.3,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 2.8,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 5.0,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 7.8,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 0.6,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 2.1,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    },
    {
        "name": "SAB32",
        "working": "Pretemps",
        "surface": {
            "value": 30,
            "symbol": "cm²"
        },
        "timeMeasuring": {
            "value": 5,
            "symbol": "s"
        },
        "detection": {
            "alpha": {
                "efficiency": {
                    "ei": {
                        "value": 38,
                        "symbol": "%"
                    },
                    "es": 0.25,
                    "ri": {
                        "value": 10,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 11,
                    "direct": {
                        "value": 0.4,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.4,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 0.7,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 0.3,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 1.0,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 1.0,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 1.0,
                        "symbol": "Bq/cm²"
                    }
                }
            },
            "beta": {
                "efficiency": {
                    "ei": {
                        "value": 24,
                        "symbol": "%"
                    },
                    "es": 0.5,
                    "ri": {
                        "value": 12,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 8,
                    "direct": {
                        "value": 0.3,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.3,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 2.8,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 5.0,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 7.8,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 2.0,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 2.0,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    },
    {
        "name": "SB70-2",
        "working": "Ictomètre/CAB",
        "surface": {
            "value": 30,
            "symbol": "cm²"
        },
        "timeMeasuring": {
            "value": 2.5,
            "symbol": "s"
        },
        "detection": {
            "beta": {
                "efficiency": {
                    "ei": {
                        "value": 33,
                        "symbol": "%"
                    },
                    "es": 0.5,
                    "ri": {
                        "value": 17,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 6,
                    "direct": {
                        "value": 0.2,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.2,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 2.8,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value":5.0,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 7.8,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 1.1,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 1.1,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    },
    {
        "name": "SB32",
        "working": "Pretemps",
        "surface": {
            "value": 30,
            "symbol": "cm²"
        },
        "timeMeasuring": {
            "value": 5,
            "symbol": "s"
        },
        "detection": {
            "beta": {
                "efficiency": {
                    "ei": {
                        "value": 41,
                        "symbol": "%"
                    },
                    "es": 0.5,
                    "ri": {
                        "value": 21,
                        "symbol": "%"
                    }
                },
                "conversion": {
                    "bq": 5,
                    "direct": {
                        "value": 0.2,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 0.2,
                        "symbol": "Bq/cm²"
                    }
                },
                "detectionThreshold": {
                    "sdNet": {
                        "value": 2.8,
                        "symbol": "c/s"
                    },
                    "usualBdf": {
                        "value": 5.0,
                        "symbol": "c/s"
                    },
                    "sdbdf": {
                        "value": 7.8,
                        "symbol": "c/s"
                    }
                },
                "limitDetection": {
                    "direct": {
                        "value": 1.2,
                        "symbol": "Bq/cm²"
                    },
                    "smear": {
                        "value": 1.2,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    }
];

export const ContaminationBenches: ReadonlyArray<BenchDevice> = [
    {
        "name": "Banc de comptage ∅60mm",
        "detection": {
            "alpha": {
                "efficiency": {
                    "ei": {
                        "value": 50,
                        "symbol": "%"
                    },
                    "es": 0.25,
                    "ri": {
                        "value": 13,
                        "symbol": "%"
                    }
                },
                "bdfMax": {
                    "value": 0.0083,
                    "symbol": "c/s"
                },
                "limitDetection600": {
                    "sample": {
                        "value": 600,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.03,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.3,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.009,
                        "symbol": "Bq/cm²"
                    }
                },
                "limitDetection300": {
                    "sample": {
                        "value": 300,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.05,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.4,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.015,
                        "symbol": "Bq/cm²"
                    }
                }
            },
            "beta": {
                "efficiency": {
                    "ei": {
                        "value": 52,
                        "symbol": "%"
                    },
                    "es": 0.5,
                    "ri": {
                        "value": 26,
                        "symbol": "%"
                    }
                },
                "bdfMax": {
                    "value": 0.092,
                    "symbol": "c/s"
                },
                "limitDetection600": {
                    "sample": {
                        "value": 600,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.08,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.3,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.01,
                        "symbol": "Bq/cm²"
                    }
                },
                "limitDetection300": {
                    "sample": {
                        "value": 300,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.1,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.5,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.016,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    },
    {
        "name": "Banc de comptage ∅135mm",
        "detection": {
            "alpha": {
                "efficiency": {
                    "ei": {
                        "value": 53,
                        "symbol": "%"
                    },
                    "es": 0.25,
                    "ri": {
                        "value": 13,
                        "symbol": "%"
                    }
                },
                "bdfMax": {
                    "value": 0.01,
                    "symbol": "c/s"
                },
                "limitDetection600": {
                    "sample": {
                        "value": 600,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.04,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.3,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.009,
                        "symbol": "Bq/cm²"
                    }
                },
                "limitDetection300": {
                    "sample": {
                        "value": 300,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.06,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.4,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.014,
                        "symbol": "Bq/cm²"
                    }
                }
            },
            "beta": {
                "efficiency": {
                    "ei": {
                        "value": 59,
                        "symbol": "%"
                    },
                    "es": 0.5,
                    "ri": {
                        "value": 30,
                        "symbol": "%"
                    }
                },
                "bdfMax": {
                    "value": 0.13,
                    "symbol": "c/s"
                },
                "limitDetection600": {
                    "sample": {
                        "value": 600,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.09,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.3,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.01,
                        "symbol": "Bq/cm²"
                    }
                },
                "limitDetection300": {
                    "sample": {
                        "value": 300,
                        "symbol": "s"
                    },
                    "ldCs": {
                        "value": 0.1,
                        "symbol": "c/s"
                    },
                    "ldBq": {
                        "value": 0.5,
                        "symbol": "Bq"
                    },
                    "ldBqCm": {
                        "value": 0.016,
                        "symbol": "Bq/cm²"
                    }
                }
            }
        }
    }
];

export const IrradiationDevices: ReadonlyArray<IrradiationDevice> = [
    {
        "name": "Babyline",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.01,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 7,
                "symbol": "mg/cm²"
            },
            {
                "value": 300,
                "symbol": "mg/cm²"
            },
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "RAM-ION",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.01,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 7,
                "symbol": "mg/cm²"
            },
            {
                "value": 300,
                "symbol": "mg/cm²"
            },
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "FH40",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.0001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Radiagem",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.003,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Radiagem-VLD",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.0001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "RADEYE",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Teletector",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Colibri",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.003,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Colibri-VLD",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.0001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Sonde STTC",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Sonde SEC",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Sonde SHI",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 0.01,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Sonde SHF",
        "detection": {
            "gamma": {
                "limitDetection": {
                    "value": 1,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Cramal",
        "detection": {
            "neutron": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "NM2",
        "detection": {
            "neutron": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "ATOMENERGY",
        "detection": {
            "neutron": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "RADEYE GN",
        "detection": {
            "neutron": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    },
    {
        "name": "Sonde SND",
        "detection": {
            "neutron": {
                "limitDetection": {
                    "value": 0.001,
                    "symbol": "mSv/h"
                }
            }
        },
        "surfaceDensity": [
            {
                "value": 1,
                "symbol": "g/cm²"
            }
        ]
    }
];

//ProbeDevice schema
const ProbeDeviceSchema =
{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "ProbeDevice",
    "type": "object",
    "properties": {
        "name": {
            "type": "string"
        },
        "serialNumber": {
            "type": ["string", "null"]
        },
        "referenceNumber": {
            "type": ["string", "null"]
        },
        "working": {
            "type": "string"
        },
        "surface": {
            "$ref": "#/$defs/surfaceUnit"
        },
        "timeMeasuring": {
            "$ref": "#/$defs/secondUnit"
        },
        "detection": {
            "type": "object",
            "properties": {
                "alpha": {
                    "$ref": "#/$defs/probeFeature"
                },
                "beta": {
                    "$ref": "#/$defs/probeFeature"
                }
            }
        }
    },
    "required": [
        "name",
        "detection"
    ],
    "$defs": {
        "percentUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["%"]
                }
            }
        },
        "surfaceUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["cm²"]
                }
            }
        },
        "surfaceBqUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["Bq/cm²"]
                }
            }
        },
        "hitSecondUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["c/s"]
                }
            }
        },
        "secondUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["s"]
                }
            }
        },
        "probeFeature": {
            "type": "object",
            "properties": {
                "efficiency": {
                    "type": "object",
                    "properties": {
                        "ei": {
                            "$ref": "#/$defs/percentUnit"
                        },
                        "es": {
                            "type": "number"
                        },
                        "ri": {
                            "$ref": "#/$defs/percentUnit"
                        }
                    },
                    "required": [
                        "ei",
                        "es",
                        "ri"
                    ]
                },
                "conversion": {
                    "type": "object",
                    "properties": {
                        "bq": {
                            "type": "number"
                        },
                        "direct": {
                            "$ref": "#/$defs/surfaceBqUnit"
                        },
                        "smear": {
                            "$ref": "#/$defs/surfaceBqUnit"
                        }
                    },
                    "required": [
                        "bq",
                        "direct",
                        "smear"
                    ]
                },
                "detectionThreshold": {
                    "type": "object",
                    "properties": {
                        "sdNet": {
                            "$ref": "#/$defs/hitSecondUnit"
                        },
                        "usualBdf": {
                            "$ref": "#/$defs/hitSecondUnit"
                        },
                        "sdbdf": {
                            "$ref": "#/$defs/hitSecondUnit"
                        }
                    },
                    "required": [
                        "sdNet",
                        "usualBdf",
                        "sdbdf"
                    ]
                },
                "limitDetection": {
                    "type": "object",
                    "properties": {
                        "direct": {
                            "$ref": "#/$defs/surfaceBqUnit"
                        },
                        "smear": {
                            "$ref": "#/$defs/surfaceBqUnit"
                        }
                    }
                }
            },
            "required": [
                "efficiency",
                "conversion",
                "detectionThreshold",
                "limitDetection"
            ]
        }
    }
}


//BenchDevice
const BenchDeviceSchema =
{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "BenchDevice",
    "type": "object",
    "properties": {
        "name": {
            "type": "string"
        },
        "serialNumber": {
            "type": ["string", "null"]
        },
        "referenceNumber": {
            "type": ["string", "null"]
        },
        "detection": {
            "type": "object",
            "properties": {
                "alpha": {
                    "$ref": "#/$defs/benchFeature"
                },
                "beta": {
                    "$ref": "#/$defs/benchFeature"
                }
            }
        }
    },
    "required": [
        "name",
        "detection"
    ],
    "$defs": {
        "percentUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["%"]
                }
            }
        },
        "bqUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["Bq"]
                }
            }
        },
        "surfaceBqUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["Bq/cm²"]
                }
            }
        },
        "hitSecondUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["c/s"]
                }
            }
        },
        "secondUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["s"]
                }
            }
        },
        "limitDetectionTBdf": {
            "type": "object",
            "properties": {
                "sample": {
                    "$ref": "#/$defs/secondUnit"
                },
                "ldCs": {
                    "$ref": "#/$defs/hitSecondUnit"
                },
                "ldBq": {
                    "$ref": "#/$defs/bqUnit"
                },
                "ldBqCm": {
                    "$ref": "#/$defs/surfaceBqUnit"
                }
            },
            "required": [
                "sample",
                "ldCs",
                "ldBq",
                "ldBqCm"
            ]
        },
        "benchFeature": {
            "type": "object",
            "properties": {
                "efficiency": {
                    "type": "object",
                    "properties": {
                        "ei": {
                            "$ref": "#/$defs/percentUnit"
                        },
                        "es": {
                            "type": "number"
                        },
                        "ri": {
                            "$ref": "#/$defs/percentUnit"
                        }
                    },
                    "required": [
                        "ei",
                        "es",
                        "ri"
                    ]
                },
                "bdfMax": {
                    "$ref": "#/$defs/hitSecondUnit"
                },
                "limitDetection300": {
                    "$ref": "#/$defs/limitDetectionTBdf"
                },
                "limitDetection600": {
                    "$ref": "#/$defs/limitDetectionTBdf"
                }
            },
            "required": [
                "efficiency",
                "bdfMax",
                "limitDetection300",
                "limitDetection600"
            ]
        }
    }
}

//IrradiationDevice
const IrradiationDeviceSchema =
{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "IrradiationDevice",
    "type": "object",
    "properties": {
        "name": {
            "type": "string"
        },
        "serialNumber": {
            "type": [
                "string",
                "null"
            ]
        },
        "referenceNumber": {
            "type": [
                "string",
                "null"
            ]
        },
        "surfaceDensity": {
            "type": "array",
            "items": {
                "$ref": "#/$defs/massPerSurfaceUnit"
            }
        },
        "detection": {
            "type": "object",
            "properties": {
                "gamma": {
                    "$ref": "#/$defs/sivertPerHourUnit"
                },
                "neutron": {
                    "$ref": "#/$defs/sivertPerHourUnit"
                }
            }
        }
    },
    "required": [
        "name",
        "detection"
    ],
    "$defs": {
        "massPerSurfaceUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": [
                        "mg/cm²",
                        "g/cm²"
                    ]
                }
            }
        },
        "sivertPerHourUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": [
                        "mSv/h"
                    ]
                }
            }
        }
    }
}

//Irradiation
const IrradiationSchema =
{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "Irradiation",
    "type": "object",
    "properties": {
        "id": {
            "type": "number"
        },
        "contact": {
            "$ref": "#/$defs/radiationValue"
        },
        "ambient": {
            "type": "object",
            "properties": {
                "includedInCalculation": {
                    "type": "boolean"
                }
            },
            "allOf": [
                { "$ref": "#/$defs/radiationValue" }
            ],
            "unevaluatedProperties": false,
            "required": [
                "includedInCalculation"
            ]
        },
        "modeControl": {
            "enum": ["Contact", "Ambient"]
        },
        "observations": {
            "type": ["string", "null"]
        }
    },
    "unevaluatedProperties": false,
    "required": [
        "id",
        "contact",
        "ambient",
        "observations",
        "modeControl"
    ],
    "$defs": {
        "sivertPerHourUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["mSv/h"]
                }
            }
        },
        "densityUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["mg/cm²", "g/cm²"]
                }
            }
        },
        "surfaceDensityRadiation": {
            "type": "object",
            "properties": {
                "reading": {
                    "$ref": "#/$defs/sivertPerHourUnit"
                },
                "surfaceDensity": {
                    "$ref": "#/$defs/densityUnit"
                },
                "measuringDevice": {
                    "$ref": "/irradiationDevice"
                }
            },
            "unevaluatedProperties": false
        },
        "radiationValue": {
            "type": "object",
            "properties": {
                "gamma": {
                    "$ref": "#/$defs/surfaceDensityRadiation"
                },
                "neutron": {
                    "$ref": "#/$defs/surfaceDensityRadiation"
                }
            }
        },
        "irradiationDevice": {
            "$schema": "https://json-schema.org/draft/2020-12/schema",
            "title": "IrradiationDevice",
            "type": "object",
            "properties": {
                "name": {
                    "type": "string"
                },
                "serialNumber": {
                    "type": [
                        "string",
                        "null"
                    ]
                },
                "referenceNumber": {
                    "type": [
                        "string",
                        "null"
                    ]
                },
                "surfaceDensity": {
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/massPerSurfaceUnit"
                    }
                },
                "detection": {
                    "type": "object",
                    "properties": {
                        "gamma": {
                            "$ref": "#/$defs/sivertPerHourUnit"
                        },
                        "neutron": {
                            "$ref": "#/$defs/sivertPerHourUnit"
                        }
                    }
                }
            },
            "required": [
                "name",
                "detection"
            ],
            "$defs": {
                "massPerSurfaceUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": [
                                "mg/cm²",
                                "g/cm²"
                            ]
                        }
                    }
                },
                "sivertPerHourUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": [
                                "mSv/h"
                            ]
                        }
                    }
                }
            }
        }
    }
}

//contamination
const ContaminationSchema =
{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "Contamination",
    "type": "object",
    "properties": {
        "id": {
            "type": "number"
        },
        "alpha": {
            "type": ["number", "null"]
        },
        "beta": {
            "type": ["number", "null"]
        },
        "unit": {
            "enum": ["c/s", "Bq/cm²"]
        },
        "rubbedSurface": {
            "$ref": "#/$defs/surfaceUnit"
        },
        "contaminatedSurface": {
            "$ref": "#/$defs/surfaceUnit"
        },
        "observations": {
            "type": "string"
        },
        "includedInCalculation": {
            "type": "boolean"
        },
        "measuringDevice": {
            "type": "object",
            "oneOf": [
                { "$ref": "/benchDevice" },
                { "$ref": "/probeDevice" }
            ]
        },
        "modeControl": {
            "enum": ["Probe", "Smear", "Paper"]
        }
    },
    "unevaluatedProperties": false,
    "required": [
        "id",
        "modeControl",
        "unit"
    ],
    "$defs": {
        "surfaceUnit": {
            "type": "object",
            "properties": {
                "value": {
                    "type": "number"
                },
                "symbol": {
                    "enum": ["cm²"]
                }
            }
        },
        "benchDevice": {
            "$id": "/benchDevice",
            "$schema": "https://json-schema.org/draft/2020-12/schema",
            "title": "BenchDevice",
            "type": "object",
            "properties": {
                "name": {
                    "type": "string"
                },
                "serialNumber": {
                    "type": ["string", "null"]
                },
                "referenceNumber": {
                    "type": ["string", "null"]
                },
                "detection": {
                    "type": "object",
                    "properties": {
                        "alpha": {
                            "$ref": "#/$defs/benchFeature"
                        },
                        "beta": {
                            "$ref": "#/$defs/benchFeature"
                        }
                    }
                }
            },
            "required": [
                "name",
                "detection"
            ],
            "$defs": {
                "percentUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["%"]
                        }
                    }
                },
                "bqUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["Bq"]
                        }
                    }
                },
                "surfaceBqUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["Bq/cm²"]
                        }
                    }
                },
                "hitSecondUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["c/s"]
                        }
                    }
                },
                "secondUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["s"]
                        }
                    }
                },
                "limitDetectionTBdf": {
                    "type": "object",
                    "properties": {
                        "sample": {
                            "$ref": "#/$defs/secondUnit"
                        },
                        "ldCs": {
                            "$ref": "#/$defs/hitSecondUnit"
                        },
                        "ldBq": {
                            "$ref": "#/$defs/bqUnit"
                        },
                        "ldBqCm": {
                            "$ref": "#/$defs/surfaceBqUnit"
                        }
                    },
                    "required": [
                        "sample",
                        "ldCs",
                        "ldBq",
                        "ldBqCm"
                    ]
                },
                "benchFeature": {
                    "type": "object",
                    "properties": {
                        "efficiency": {
                            "type": "object",
                            "properties": {
                                "ei": {
                                    "$ref": "#/$defs/percentUnit"
                                },
                                "es": {
                                    "type": "number"
                                },
                                "ri": {
                                    "$ref": "#/$defs/percentUnit"
                                }
                            },
                            "required": [
                                "ei",
                                "es",
                                "ri"
                            ]
                        },
                        "bdfMax": {
                            "$ref": "#/$defs/hitSecondUnit"
                        },
                        "limitDetection300": {
                            "$ref": "#/$defs/limitDetectionTBdf"
                        },
                        "limitDetection600": {
                            "$ref": "#/$defs/limitDetectionTBdf"
                        }
                    },
                    "required": [
                        "efficiency",
                        "bdfMax",
                        "limitDetection300",
                        "limitDetection600"
                    ]
                }
            }
        },
        "probeDevice": {
            "$id": "/probeDevice",
            "$schema": "https://json-schema.org/draft/2020-12/schema",
            "title": "ProbeDevice",
            "type": "object",
            "properties": {
                "name": {
                    "type": "string"
                },
                "serialNumber": {
                    "type": ["string", "null"]
                },
                "referenceNumber": {
                    "type": ["string", "null"]
                },
                "working": {
                    "type": "string"
                },
                "surface": {
                    "$ref": "#/$defs/surfaceUnit"
                },
                "timeMeasuring": {
                    "$ref": "#/$defs/secondUnit"
                },
                "detection": {
                    "type": "object",
                    "properties": {
                        "alpha": {
                            "$ref": "#/$defs/probeFeature"
                        },
                        "beta": {
                            "$ref": "#/$defs/probeFeature"
                        }
                    }
                }
            },
            "required": [
                "name",
                "detection"
            ],
            "$defs": {
                "percentUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["%"]
                        }
                    }
                },
                "surfaceUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["cm²"]
                        }
                    }
                },
                "surfaceBqUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["Bq/cm²"]
                        }
                    }
                },
                "hitSecondUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["c/s"]
                        }
                    }
                },
                "secondUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": ["s"]
                        }
                    }
                },
                "probeFeature": {
                    "type": "object",
                    "properties": {
                        "efficiency": {
                            "type": "object",
                            "properties": {
                                "ei": {
                                    "$ref": "#/$defs/percentUnit"
                                },
                                "es": {
                                    "type": "number"
                                },
                                "ri": {
                                    "$ref": "#/$defs/percentUnit"
                                }
                            },
                            "required": [
                                "ei",
                                "es",
                                "ri"
                            ]
                        },
                        "conversion": {
                            "type": "object",
                            "properties": {
                                "bq": {
                                    "type": "number"
                                },
                                "direct": {
                                    "$ref": "#/$defs/surfaceBqUnit"
                                },
                                "smear": {
                                    "$ref": "#/$defs/surfaceBqUnit"
                                }
                            },
                            "required": [
                                "bq",
                                "direct",
                                "smear"
                            ]
                        },
                        "detectionThreshold": {
                            "type": "object",
                            "properties": {
                                "sdNet": {
                                    "$ref": "#/$defs/hitSecondUnit"
                                },
                                "usualBdf": {
                                    "$ref": "#/$defs/hitSecondUnit"
                                },
                                "sdbdf": {
                                    "$ref": "#/$defs/hitSecondUnit"
                                }
                            },
                            "required": [
                                "sdNet",
                                "usualBdf",
                                "sdbdf"
                            ]
                        },
                        "limitDetection": {
                            "type": "object",
                            "properties": {
                                "direct": {
                                    "$ref": "#/$defs/surfaceBqUnit"
                                },
                                "smear": {
                                    "$ref": "#/$defs/surfaceBqUnit"
                                }
                            }
                        }
                    },
                    "required": [
                        "efficiency",
                        "conversion",
                        "detectionThreshold",
                        "limitDetection"
                    ]
                }
            }
        }
    }
}

//employee
const EmployeeSchema =
{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "employee",
    "type": "object",
    "properties": {
        "fullName": {
            "type": "string"
        }
    },
    "additionalProperties": false,
    "required": [
        "fullName"
    ]
}

const CartographySchema =
{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "Cartography",
    "type": "object",
    "additionalProperties": false,
    "properties": {
        "date": {
            "type": "string",
            "format": "date-time"
        },
        "room": {
            "type": "string"
        },
        "wasteZoning": {
            "enum": [
                "ZDN",
                "ZDC"
            ]
        },
        "radiologicalZoning": {
            "enum": [
                "public",
                "supervised",
                "green",
                "yellow",
                "orange",
                "forbidden"
            ]
        },
        "controllers": {
            "type": "array",
            "items": {
                "$ref": "/employee"
            }
        },
        "auditors": {
            "type": "array",
            "items": {
                "$ref": "/employee"
            }
        },
        "reasonForMapping": {
            "type": "string"
        },
        "observations": {
            "type": [
                "string",
                "null"
            ]
        },
        "contaminations": {
            "type": "array",
            "items": {
                "$ref": "/contamination"
            }
        },
        "irradiations": {
            "type": "array",
            "items": {
                "$ref": "/irradiation"
            }
        },
        "usedDevice": {
            "type": "object",
            "properties": {
                "contamination": {
                    "type": "object",
                    "properties": {
                        "probes": {
                            "type": "array",
                            "items": {
                                "$ref": "/contamination#/$defs/probeDevice"
                            }
                        },
                        "benches": {
                            "type": "array",
                            "items": {
                                "$ref": "/contamination#/$defs/benchDevice"
                            }
                        }
                    }
                },
                "irradiations": {
                    "type": "array",
                    "items": {
                        "$ref": "/irradiation#/$defs/irradiationDevice"
                    }
                }
            }
        }
    },
    "required": [
        "date",
        "room",
        "wasteZoning",
        "radiologicalZoning",
        "controllers",
        "auditors",
        "reasonForMapping",
        "observations",
        "contaminations"
    ],
    "$defs": {
        "employee": {
            "$id": "/employee",
            "$schema": "https://json-schema.org/draft/2020-12/schema",
            "title": "employee",
            "type": "object",
            "properties": {
                "fullName": {
                    "type": "string"
                }
            },
            "additionalProperties": false,
            "required": [
                "fullName"
            ]
        },
        "contamination": {
            "$id": "/contamination",
            "$schema": "https://json-schema.org/draft/2020-12/schema",
            "title": "Contamination",
            "type": "object",
            "properties": {
                "id": {
                    "type": "number"
                },
                "alpha": {
                    "type": ["number", "null"]
                },
                "beta": {
                    "type": ["number", "null"]
                },
                "unit": {
                    "enum": [
                        "c/s",
                        "Bq/cm²"
                    ]
                },
                "rubbedSurface": {
                    "$ref": "#/$defs/surfaceUnit"
                },
                "contaminatedSurface": {
                    "$ref": "#/$defs/surfaceUnit"
                },
                "observations": {
                    "type": "string"
                },
                "includedInCalculation": {
                    "type": "boolean"
                },
                "measuringDevice": {
                    "type": "object",
                    "oneOf": [
                        {
                            "$ref": "/benchDevice"
                        },
                        {
                            "$ref": "/probeDevice"
                        }
                    ]
                },
                "modeControl": {
                    "enum": [
                        "Probe",
                        "Smear",
                        "Paper"
                    ]
                }
            },
            "unevaluatedProperties": false,
            "required": [
                "id",
                "modeControl",
                "unit"
            ],
            "$defs": {
                "surfaceUnit": {
                    "type": [
                        "object",
                        "null"
                    ],
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": [
                                "cm²"
                            ]
                        }
                    }
                },
                "benchDevice": {
                    "$id": "/benchDevice",
                    "$schema": "https://json-schema.org/draft/2020-12/schema",
                    "title": "BenchDevice",
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string"
                        },
                        "serialNumber": {
                            "type": [
                                "string",
                                "null"
                            ]
                        },
                        "referenceNumber": {
                            "type": [
                                "string",
                                "null"
                            ]
                        },
                        "detection": {
                            "type": "object",
                            "properties": {
                                "alpha": {
                                    "$ref": "#/$defs/benchFeature"
                                },
                                "beta": {
                                    "$ref": "#/$defs/benchFeature"
                                }
                            }
                        }
                    },
                    "required": [
                        "name",
                        "detection"
                    ],
                    "$defs": {
                        "percentUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "%"
                                    ]
                                }
                            }
                        },
                        "bqUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "Bq"
                                    ]
                                }
                            }
                        },
                        "surfaceBqUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "Bq/cm²"
                                    ]
                                }
                            }
                        },
                        "hitSecondUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "c/s"
                                    ]
                                }
                            }
                        },
                        "secondUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "s"
                                    ]
                                }
                            }
                        },
                        "limitDetectionTBdf": {
                            "type": "object",
                            "properties": {
                                "sample": {
                                    "$ref": "#/$defs/secondUnit"
                                },
                                "ldCs": {
                                    "$ref": "#/$defs/hitSecondUnit"
                                },
                                "ldBq": {
                                    "$ref": "#/$defs/bqUnit"
                                },
                                "ldBqCm": {
                                    "$ref": "#/$defs/surfaceBqUnit"
                                }
                            },
                            "required": [
                                "sample",
                                "ldCs",
                                "ldBq",
                                "ldBqCm"
                            ]
                        },
                        "benchFeature": {
                            "type": "object",
                            "properties": {
                                "efficiency": {
                                    "type": "object",
                                    "properties": {
                                        "ei": {
                                            "$ref": "#/$defs/percentUnit"
                                        },
                                        "es": {
                                            "type": "number"
                                        },
                                        "ri": {
                                            "$ref": "#/$defs/percentUnit"
                                        }
                                    },
                                    "required": [
                                        "ei",
                                        "es",
                                        "ri"
                                    ]
                                },
                                "bdfMax": {
                                    "$ref": "#/$defs/hitSecondUnit"
                                },
                                "limitDetection300": {
                                    "$ref": "#/$defs/limitDetectionTBdf"
                                },
                                "limitDetection600": {
                                    "$ref": "#/$defs/limitDetectionTBdf"
                                }
                            },
                            "required": [
                                "efficiency",
                                "bdfMax",
                                "limitDetection300",
                                "limitDetection600"
                            ]
                        }
                    }
                },
                "probeDevice": {
                    "$id": "/probeDevice",
                    "$schema": "https://json-schema.org/draft/2020-12/schema",
                    "title": "ProbeDevice",
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string"
                        },
                        "serialNumber": {
                            "type": [
                                "string",
                                "null"
                            ]
                        },
                        "referenceNumber": {
                            "type": [
                                "string",
                                "null"
                            ]
                        },
                        "working": {
                            "type": "string"
                        },
                        "surface": {
                            "$ref": "#/$defs/surfaceUnit"
                        },
                        "timeMeasuring": {
                            "$ref": "#/$defs/secondUnit"
                        },
                        "detection": {
                            "type": "object",
                            "properties": {
                                "alpha": {
                                    "$ref": "#/$defs/probeFeature"
                                },
                                "beta": {
                                    "$ref": "#/$defs/probeFeature"
                                }
                            }
                        }
                    },
                    "required": [
                        "name",
                        "detection"
                    ],
                    "$defs": {
                        "percentUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "%"
                                    ]
                                }
                            }
                        },
                        "surfaceUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "cm²"
                                    ]
                                }
                            }
                        },
                        "surfaceBqUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "Bq/cm²"
                                    ]
                                }
                            }
                        },
                        "hitSecondUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "c/s"
                                    ]
                                }
                            }
                        },
                        "secondUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "s"
                                    ]
                                }
                            }
                        },
                        "probeFeature": {
                            "type": "object",
                            "properties": {
                                "efficiency": {
                                    "type": "object",
                                    "properties": {
                                        "ei": {
                                            "$ref": "#/$defs/percentUnit"
                                        },
                                        "es": {
                                            "type": "number"
                                        },
                                        "ri": {
                                            "$ref": "#/$defs/percentUnit"
                                        }
                                    },
                                    "required": [
                                        "ei",
                                        "es",
                                        "ri"
                                    ]
                                },
                                "conversion": {
                                    "type": "object",
                                    "properties": {
                                        "bq": {
                                            "type": "number"
                                        },
                                        "direct": {
                                            "$ref": "#/$defs/surfaceBqUnit"
                                        },
                                        "smear": {
                                            "$ref": "#/$defs/surfaceBqUnit"
                                        }
                                    },
                                    "required": [
                                        "bq",
                                        "direct",
                                        "smear"
                                    ]
                                },
                                "detectionThreshold": {
                                    "type": "object",
                                    "properties": {
                                        "sdNet": {
                                            "$ref": "#/$defs/hitSecondUnit"
                                        },
                                        "usualBdf": {
                                            "$ref": "#/$defs/hitSecondUnit"
                                        },
                                        "sdbdf": {
                                            "$ref": "#/$defs/hitSecondUnit"
                                        }
                                    },
                                    "required": [
                                        "sdNet",
                                        "usualBdf",
                                        "sdbdf"
                                    ]
                                },
                                "limitDetection": {
                                    "type": "object",
                                    "properties": {
                                        "direct": {
                                            "$ref": "#/$defs/surfaceBqUnit"
                                        },
                                        "smear": {
                                            "$ref": "#/$defs/surfaceBqUnit"
                                        }
                                    }
                                }
                            },
                            "required": [
                                "efficiency",
                                "conversion",
                                "detectionThreshold",
                                "limitDetection"
                            ]
                        }
                    }
                }
            }
        },
        "irradiation": {
            "$id": "/irradiation",
            "$schema": "https://json-schema.org/draft/2020-12/schema",
            "title": "Irradiation",
            "type": "object",
            "properties": {
                "id": {
                    "type": "number"
                },
                "contact": {
                    "$ref": "#/$defs/radiationValue"
                },
                "ambient": {
                    "type": "object",
                    "properties": {
                        "includedInCalculation": {
                            "type": "boolean"
                        }
                    },
                    "allOf": [
                        {
                            "$ref": "#/$defs/radiationValue"
                        }
                    ],
                    "unevaluatedProperties": false,
                    "required": [
                        "includedInCalculation"
                    ]
                },
                "modeControl": {
                    "enum": [
                        "Contact",
                        "Ambient"
                    ]
                },
                "observations": {
                    "type": [
                        "string",
                        "null"
                    ]
                }
            },
            "unevaluatedProperties": false,
            "required": [
                "id",
                "contact",
                "ambient",
                "observations",
                "modeControl"
            ],
            "$defs": {
                "sivertPerHourUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": [
                                "mSv/h"
                            ]
                        }
                    }
                },
                "densityUnit": {
                    "type": "object",
                    "properties": {
                        "value": {
                            "type": "number"
                        },
                        "symbol": {
                            "enum": [
                                "mg/cm²",
                                "g/cm²"
                            ]
                        }
                    }
                },
                "surfaceDensityRadiation": {
                    "type": "object",
                    "properties": {
                        "reading": {
                            "$ref": "#/$defs/sivertPerHourUnit"
                        },
                        "surfaceDensity": {
                            "$ref": "#/$defs/densityUnit"
                        },
                        "measuringDevice": {
                            "$ref": "/irradiationDevice"
                        }
                    },
                    "unevaluatedProperties": false
                },
                "radiationValue": {
                    "type": "object",
                    "properties": {
                        "gamma": {
                            "$ref": "#/$defs/surfaceDensityRadiation"
                        },
                        "neutron": {
                            "$ref": "#/$defs/surfaceDensityRadiation"
                        }
                    }
                },
                "irradiationDevice": {
                    "$id": "/irradiationDevice",
                    "$schema": "https://json-schema.org/draft/2020-12/schema",
                    "title": "IrradiationDevice",
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string"
                        },
                        "serialNumber": {
                            "type": [
                                "string",
                                "null"
                            ]
                        },
                        "referenceNumber": {
                            "type": [
                                "string",
                                "null"
                            ]
                        },
                        "surfaceDensity": {
                            "type": "array",
                            "items": {
                                "$ref": "#/$defs/massPerSurfaceUnit"
                            }
                        },
                        "detection": {
                            "type": "object",
                            "properties": {
                                "gamma": {
                                    "$ref": "#/$defs/sivertPerHourUnit"
                                },
                                "neutron": {
                                    "$ref": "#/$defs/sivertPerHourUnit"
                                }
                            }
                        }
                    },
                    "required": [
                        "name",
                        "detection"
                    ],
                    "$defs": {
                        "massPerSurfaceUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "mg/cm²",
                                        "g/cm²"
                                    ]
                                }
                            }
                        },
                        "sivertPerHourUnit": {
                            "type": "object",
                            "properties": {
                                "value": {
                                    "type": "number"
                                },
                                "symbol": {
                                    "enum": [
                                        "mSv/h"
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
